import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

const middleware = [thunk];

//if (IS_NOISY) {
    //middleware.push(createLogger());
//}

const configureStore = (preloadedState) => {
    return createStore(rootReducer, preloadedState, compose(applyMiddleware(...middleware)));
};

export default configureStore;
